import { default as confirmNQS85OT6YzMeta } from "/home/runner/work/diurnis/diurnis/pages/auth/confirm.vue?macro=true";
import { default as forgot_45passwordVJQT8s1hcSMeta } from "/home/runner/work/diurnis/diurnis/pages/auth/forgot-password.vue?macro=true";
import { default as registerhtRu8a8JxhMeta } from "/home/runner/work/diurnis/diurnis/pages/auth/register.vue?macro=true";
import { default as editjrD2MGT8oRMeta } from "/home/runner/work/diurnis/diurnis/pages/chapters/[id]/edit.vue?macro=true";
import { default as indexMtZoEKGVdCMeta } from "/home/runner/work/diurnis/diurnis/pages/chapters/[id]/index.vue?macro=true";
import { default as how_45to_45useBTi4GZL3SDMeta } from "/home/runner/work/diurnis/diurnis/pages/how-to-use.vue?macro=true";
import { default as indexv9ujLBGV8zMeta } from "/home/runner/work/diurnis/diurnis/pages/index/index.vue?macro=true";
import { default as add_45chaptervZejGyle0gMeta } from "/home/runner/work/diurnis/diurnis/pages/novels/[id]/add-chapter.vue?macro=true";
import { default as indexjILSNS92yEMeta } from "/home/runner/work/diurnis/diurnis/pages/novels/[id]/index.vue?macro=true";
import { default as indexvr8CvX8BEzMeta } from "/home/runner/work/diurnis/diurnis/pages/novels/add/index.vue?macro=true";
import { default as crawlgH7hTWurKjMeta } from "/home/runner/work/diurnis/diurnis/pages/novels/crawl.vue?macro=true";
import { default as indexwSlZv92pWyMeta } from "/home/runner/work/diurnis/diurnis/pages/novels/index.vue?macro=true";
import { default as privacy_45policyNWENmrt5ihMeta } from "/home/runner/work/diurnis/diurnis/pages/privacy-policy.vue?macro=true";
import { default as profiles9gr8GQboVMeta } from "/home/runner/work/diurnis/diurnis/pages/profile.vue?macro=true";
import { default as component_45stubuVCayacSF5Meta } from "/home/runner/work/diurnis/diurnis/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubuVCayacSF5 } from "/home/runner/work/diurnis/diurnis/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "auth-confirm",
    path: "/auth/confirm",
    component: () => import("/home/runner/work/diurnis/diurnis/pages/auth/confirm.vue")
  },
  {
    name: "auth-forgot-password",
    path: "/auth/forgot-password",
    component: () => import("/home/runner/work/diurnis/diurnis/pages/auth/forgot-password.vue")
  },
  {
    name: "auth-register",
    path: "/auth/register",
    component: () => import("/home/runner/work/diurnis/diurnis/pages/auth/register.vue")
  },
  {
    name: "chapters-id-edit",
    path: "/chapters/:id()/edit",
    meta: editjrD2MGT8oRMeta || {},
    component: () => import("/home/runner/work/diurnis/diurnis/pages/chapters/[id]/edit.vue")
  },
  {
    name: "chapters-id",
    path: "/chapters/:id()",
    meta: indexMtZoEKGVdCMeta || {},
    component: () => import("/home/runner/work/diurnis/diurnis/pages/chapters/[id]/index.vue")
  },
  {
    name: "how-to-use",
    path: "/how-to-use",
    component: () => import("/home/runner/work/diurnis/diurnis/pages/how-to-use.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/diurnis/diurnis/pages/index/index.vue")
  },
  {
    name: "novels-id-add-chapter",
    path: "/novels/:id()/add-chapter",
    meta: add_45chaptervZejGyle0gMeta || {},
    component: () => import("/home/runner/work/diurnis/diurnis/pages/novels/[id]/add-chapter.vue")
  },
  {
    name: "novels-id",
    path: "/novels/:id()",
    meta: indexjILSNS92yEMeta || {},
    component: () => import("/home/runner/work/diurnis/diurnis/pages/novels/[id]/index.vue")
  },
  {
    name: "novels-add",
    path: "/novels/add",
    meta: indexvr8CvX8BEzMeta || {},
    component: () => import("/home/runner/work/diurnis/diurnis/pages/novels/add/index.vue")
  },
  {
    name: "novels-crawl",
    path: "/novels/crawl",
    meta: crawlgH7hTWurKjMeta || {},
    component: () => import("/home/runner/work/diurnis/diurnis/pages/novels/crawl.vue")
  },
  {
    name: "novels",
    path: "/novels",
    component: () => import("/home/runner/work/diurnis/diurnis/pages/novels/index.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/home/runner/work/diurnis/diurnis/pages/privacy-policy.vue")
  },
  {
    name: "profile",
    path: "/profile",
    meta: profiles9gr8GQboVMeta || {},
    component: () => import("/home/runner/work/diurnis/diurnis/pages/profile.vue")
  },
  {
    name: component_45stubuVCayacSF5Meta?.name,
    path: "/ads.txt",
    component: component_45stubuVCayacSF5
  }
]